<template>
  <!-- Nearby stock search modal -->
  <template v-if="isMobile">
    <TsDrawer
      :data-testid="props.dataTestid"
      v-model:visible="stockStore.stock_finder_modal_visible"
      position="bottom"
      append-class="bg-transparent overflow-hidden"
      @drawer-close="branchStore.localitySearchInput = ''"
    >
      <TsModalNearbyStockFinder
        @add-for-collection="
          (branch, quantity) => handleInstantCollection(branch, quantity)
        "
        :loading="stockStore.is_nearby_stock_search_loading"
        :cta-loading="collectionCTALoading"
        @select-locality-suggestion="
            (selectedSuggestion) => onSelectSuggestion(selectedSuggestion?.public_id as string)
          "
        @select-current-location="handleCurrentLocationStockSearch"
        @close="stockStore.stock_finder_modal_visible = false"
      />
    </TsDrawer>
  </template>
  <template v-if="isDesktop">
    <TsModal
      :data-testid="props.dataTestid"
      v-model:visible="stockStore.stock_finder_modal_visible"
      position="bottom"
      append-class="bg-transparent max-w-[636px] max-h-[700px] overflow-y-auto no-scrollbar top-20"
      @hide="branchStore.localitySearchInput = ''"
    >
      <template #modal-content>
        <TsModalNearbyStockFinder
          @add-for-collection="
            (branch, quantity) => handleInstantCollection(branch, quantity)
          "
          :loading="stockStore.is_nearby_stock_search_loading"
          :cta-loading="collectionCTALoading"
          @select-locality-suggestion="
              (selectedSuggestion) => onSelectSuggestion(selectedSuggestion?.public_id as string)
            "
          @select-current-location="handleCurrentLocationStockSearch"
          @close="stockStore.stock_finder_modal_visible = false"
        />
      </template>
    </TsModal>
  </template>
</template>

<script lang="ts" setup>
import type { Branch } from "~/types/ecom/branch.type";
import type { Product } from "~/types/ecom/product/product.type";

// PROPS
type Props = {
  id?: string;
  product: Product;
  dataTestid: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  postAdd: any;
}>();

const { isMobile, isDesktop } = useDevice();
const branchStore = useBranchStore();
const stockStore = useStockStore();

const collectionCTALoading = ref(false);

//---- Checkout action
async function handleInstantCollection(
  selectedBranch: Branch,
  quantity: number
) {
  const localePath = useLocalePath();
  collectionCTALoading.value = true;

  // save the branch and add to one time buy now trolley
  await useCheckoutStore().addToBuyNowForCollection(
    props.product.code,
    quantity,
    selectedBranch
  );

  collectionCTALoading.value = false;
  stockStore.stock_finder_modal_visible = false;

  await navigateTo(localePath("/buy-now"));

  // emit for any side effects such as opening confirmation modal
  emit("postAdd");
}

async function onSelectSuggestion(publicId: string) {
  const localityDetailsResult = await branchStore.getLocalityDetails(publicId);

  if (!localityDetailsResult || !localityDetailsResult.geometry) return;

  const latitude = String(localityDetailsResult.geometry.location.lat);
  const longitude = String(localityDetailsResult.geometry.location.lng);

  stockStore.is_nearby_stock_search_loading = true;

  await handleNearByStockSearch({
    latitude,
    longitude,
    productCode: props.product.code,
    requiredQuantity: 1,
  });

  stockStore.is_nearby_stock_search_loading = false;
}

//---- filter by current location
async function handleCurrentLocationStockSearch() {
  
  await branchStore.useCurrentLocation();
  if (!branchStore.currentLocation) return;

  const latitude = branchStore.currentLocation.latitude;
  const longitude = branchStore.currentLocation.longitude;

  stockStore.is_nearby_stock_search_loading = true;

  await handleNearByStockSearch({
    latitude,
    longitude,
    productCode: props.product.code,
    requiredQuantity: 1,
  });

  stockStore.is_nearby_stock_search_loading = false;
}
</script>
